<style>
</style>

<template>
<v-container lighten-5>
    <v-row>
        <v-col cols="12" sm="12" md="12">
            <span class="mx-2 secondary--text display-2 float-left"> {{ this.$route.meta.title }}</span>
        </v-col>
        <v-col cols="12" sm="12" md="12" v-if="tabla">
            <v-card elevation="0" class="rounded-xl">
                <v-data-iterator :items="lista" :page.sync="page" :loading="loadingTable" :item-key="tabla.itemKey" :items-per-page="10" single-expand hide-default-footer :sort-by="tabla.orderBy" :sort-desc="tabla.orderDesc">
                    <template v-slot:header>
                        <Dfiltros v-if="verFiltros" :onBuscar="search" v-bind:filtros="filtros" />
                        <v-toolbar dark class="mb-1">
                            <v-btn class="mx-2 primary--text" tile color="white" @click.stop="dialog = true">
                                agregar
                            </v-btn>
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <div class="flex-grow-1"></div>
                        </v-toolbar>
                    </template>
                    <template v-slot:default="{ items, isExpanded, expand }">
                        <CardTable v-bind:table="{ tabla, data:{ items, isExpanded, expand }}"></CardTable>
                    </template>
                    <template v-slot:no-data>
                        <v-row class="mt-2" align="center" justify="center">
                            <v-col cols="10" sm="10" md="10" lg="10">
                                <v-alert text dense color="primary" icon="mdi-database-search" border="left">
                                    No se encontro nada.
                                </v-alert>
                            </v-col>
                        </v-row>
                    </template>
                    <template v-slot:loading>
                        <v-row class="mt-2" align="center" justify="center">
                            <v-col cols="12" sm="12" md="12" lg="12">
                                <Loader />
                            </v-col>
                        </v-row>
                    </template>
                    <template v-slot:footer>
                        <Paginacion v-on:cambiarPagina="page = $event" :onSearch="search" v-bind:propiedades="{filtros,page,pageCount,Count}" />
                    </template>
                </v-data-iterator>
            </v-card>
        </v-col>
    </v-row>
            <v-dialog v-model="dialog" persistent width="800px" max-width="800px">
                <v-card>
                    <v-card-title>
                        <span class="headline">{{ formTitle }}</span>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="12" sm="12" md="12">
                                    <v-text-field outlined label="Titulo" :error="$v.form.coviTitulo.$error" v-model="form.coviTitulo" v-on:keyup="$data.form.coviTitulo = $event.target.value.toUpperCase()"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="12">
                                    <v-textarea outlined label="Descripcion" :error="$v.form.coviDescripcion.$error" v-model="form.coviDescripcion" v-on:keyup="$data.form.coviDescripcion = $event.target.value.toUpperCase()"></v-textarea>
                                </v-col>
                                <v-col cols="12" sm="12" md="12">
                                    <v-textarea outlined label="Link" v-model="form.coviLink" :error="$v.form.coviLink.$error"></v-textarea>
                                </v-col>
                                <v-col cols="12" sm="4" md="4">
                                  <v-text-field v-if="nuevo" :error="$v.form.coviCategoria.$error" append-icon="search" @click:append="nuevo=!nuevo" dense outlined  v-on:keyup="$data.form.coviCategoria = $event.target.value.toUpperCase()" label="Categoria" v-model="form.coviCategoria"></v-text-field>
                                    <v-autocomplete v-if="!nuevo" :error="$v.form.coviCategoria.$error" append-outer-icon="edit" @click:append-outer="nuevo=!nuevo"  dense  outlined v-model="form.coviCategoria" :items="categorias.categorias" item-text="coviCategoria" item-value="coviCategoria" label="Categoria" :search-input.sync="categorias.search" :error-messages="categorias.error" @keyup="filtrarCategorias()"  required hide-no-data hide-selected  clearable> </v-autocomplete>
                                </v-col>
                                 <v-col cols="12" sm="4" md="4">
                                    <v-select v-model="form.coviRoles" :error="$v.form.coviRoles.$error" dense :items="perfiles" multiple chips small-chips item-text="description"  outlined item-value="name" label="perfiles" clearable></v-select>
                                </v-col>
                                <v-col cols="12" sm="12" md="12">
                                  <v-switch v-model="form.coviVisible" label="Visible"></v-switch>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <div class="flex-grow-1"></div>
                        <v-btn color="primary darken-1" text @click="close">Cancelar</v-btn>
                        <v-btn color="primary darken-1" dark @click="agregar">Guardar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-model="dialog2" persistent width="800px" max-width="800px">
                <v-card>
                    <v-card-title>
                        <span class="headline">{{ titulo }}</span>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="12" sm="12" md="12">
                                  <div id="contendorvideo"></div>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <div class="flex-grow-1"></div>
                        <v-btn color="primary darken-1" text @click="cerrarVideo">Cerrar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
    </v-container>
</template>

<script>
import generales from '../../mixins/generales.js';
import {
  required
}
from 'vuelidate/lib/validators';
export default {
  mixins: [generales],
  data() {
    return {

      dialog2: false,
      loader: false,
      Count: 0,
      page: 1,
      pageCount: 0,
      nuevo:false,
      url: "sistema/videos/",
      video:"",
      titulo:"",
      form: {
        coviId: null,
        coviTitulo: null,
        coviDescripcion: '',
        coviVisible:1,
        coviRoles:"",
        coviCategoria:"",
        coviLink:""
      },
      tabla: {
                itemKey: 'coviId',
                orderBy: 'coviFecha',
                orderDesc: true,
                expandible: false,
                expandibleKey: 'coviId',
                expandibleTitle: () => { return `` },
                expandibleItems: () => { return [] },
                primarios: [{
                        titulo: 'TITULO',
                        tamaño: {
                            cols: '12',
                            sm: '12',
                            md: '12',
                            lg: '12'
                        },
                        class: 'primary--text subtitle-1',
                        valor: (item) => { return item.coviTitulo },
                        show: (item) => { return item ? true : false }
                    }, {
                        titulo: 'FECHA',
                        tamaño: {
                            cols: '12',
                            sm: '12',
                            md: '3',
                            lg: '3'
                        },
                        class: 'green--text subtitle-1',
                        valor: (item) => { return item.coviFecha },
                        show: (item) => { return item ? true : false }
                    },
                    {
                        titulo: 'CATEGORIA',
                        tamaño: {
                            cols: '12',
                            sm: '12',
                            md: '3',
                            lg: '3'
                        },
                        class: 'primary--text',
                        valor: (item) => { return item.coviCategoria },
                        show: (item) => { return item ? true : false }
                    }
                ],
                segundarios: [
                    {
                        titulo: 'DESCRIPCION',
                        tamaño: {
                            cols: '12',
                            sm: '12',
                            md: '12',
                            lg: '12'
                        },
                        class: 'primary--text',
                        valor: (item) => { return (item.coviDescripcion) ? item.coviDescripcion : '' },
                        show: (item) => { return item ? true : false }
                    }
                ],
                expanItem: [],
                expanMenu: [],
                menu: [
                {
                        title: 'Ver',
                        icon: 'mdi-eye',
                        color: 'success',
                        tipeAction: 'funcion',
                        action: (item) => { this.dialog2=true; this.titulo=item.coviTitulo ; setTimeout(()=>{document.getElementById("contendorvideo").innerHTML = item.coviLink},1500) },
                        show: (item) => { return item ? true  : false }
                    },
                    {
                        title: 'Editar',
                        icon: 'create',
                        color: 'info',
                        tipeAction: 'funcion',
                        action: (item) => { this.dialog = true; return this.editar(item.coviId) },
                        show: (item) => { return item ? (this.getPermiso('editarVideos') || this.user.role.indexOf('ADMIN') > -1): false }

                    },
                    {
                        title: 'Eliminar',
                        icon: 'delete',
                        color: 'error',
                        tipeAction: 'funcion',
                        action: (item) => { this.eliminar(item.coviId) },
                        show: (item) => { return item ? (this.getPermiso('eliminarVideos')) || this.user.role.indexOf('ADMIN') > -1  : false }
                    },
                ]
            },
      filtros: [{
          campo:"coviTitulo",
          text: "Titulo",
          value: null,
          tipo: "text",
          search: true,
          isLoading: false
        },
        {
          campo:"coviCategoria",
          text: "Categoria",
          value: null,
          tipo: "text",
          search: true,
          isLoading: false
        },
        {    
            campo: "coviRoles",
            text: "role",
            value: null,
            tipo: "lista",
            lista: [],
            listatext: "description",
            listavalue: "name"
        }
      ],
      categorias:{
                categorias:[],
                search:null,
                error:""
            },
            perfiles:[]
    };
  },

  watch: {
    dialog(val) {
      val || this.close();
    }
  },

  validations: {
    form: {
        coviTitulo: {
        required,
      },
      coviDescripcion: {
        required,
      },
      coviRoles: {
        required,
      },
      coviCategoria: {
        required,
      },
      coviLink:{
        required,
      },coviTipo:{
        required,
      },
    },
  },
  created: async function() {
    if(this.user.role.indexOf('ADMIN') > -1){
      this.filtros[2].lista = this.perfiles = await this.$apiService.index("sistema/perfiles/index/1").then(data => data);
    }else{
      this.filtros[2].value = this.user.role
    }
    
    await this.search(this.filtros);
    this.form.coviTipo = this.$route.name == 'Tutoriales'?'VIDEO':'DOCUMENTO';
  },

  methods: {
    cerrarVideo(){
      setTimeout(()=>{document.getElementById('contendorvideo').innerHTML = null},1500); 
      this.dialog2=false;
    },
    async filtrarCategorias(){
            if (this.categorias.search && this.categorias.search.length > 4) {
            this.categorias.error ="";
            this.categorias.categorias =  await this.$apiService.index(`${this.url}categorias?categoria=${this.categorias.search}`).then(data => data);
            }else{
                this.categorias.categorias = []
                this.categorias.error = "Escriba 4 letras como minimo"
            }
        },
    async search(filtros) {
            this.lista = this.listaCopia = [];
            this.loadingTable = true;
            const data = await this.$apiService.index(`${this.url}buscar?${this.filtrar(filtros,this.page)}coviTipo=${this.$route.name == 'Tutoriales'?'VIDEO':'DOCUMENTO'}`).then(data => data);
            if (data) {
                this.lista = this.listaCopia = data.rows;
                this.Count = data.count;
                this.pageCount = Math.ceil((data.count) / 10);
            }
            this.loadingTable = false;
        },
   
        async agregar() {
      this.$v.form.$touch();
      if (!this.$v.form.$error) {
        try {
          this.form.coviRoles = this.form.coviRoles.join(',');
          if (this.isNew) {
            let data = await this.$apiService.create(this.url + "create", this.form).then(data => data);
            if (data) {
              await this.search(this.filtros);
              this.$swal.fire({
                title: "Completado!",
                text: "Registro exitoso",
                icon: "success",
                confirmButtonText: "Ok"
              });
            }
          } else {
            var id = this.form.coviId;
            let data = await this.$apiService.update(this.url + "update/" + id + "/", this.form).then(data => data);
            if (data) {
              await this.search(this.filtros);
              this.$swal.fire({
                title: "Completado!",
                text: "Registro exitoso",
                icon: "success",
                confirmButtonText: "Ok"
              });
            }
          }
        } catch (error) {
          this.$swal.fire({
            title: "Algo sucedio!",
            text: "contacta con el administrador",
            icon: "error"
          });
        }
        this.close();
      }
    },
    async eliminar(id) {
      try {
        var result = await this.$swal.fire({
            title: "Estas seguro?",
            text: "No podras revertir esto!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si, eliminar!"
          }).then(result => result.value);
        if (result) {
          let data = await this.$apiService.delete(this.url + "delete/" + id + "/").then(data => data);
          if (data) {
            await this.search(this.filtros);
            this.$swal.fire(
              "Eliminado!",
              "ha sido eliminado con exito.",
              "success"
            );
          }

        }
      } catch (error) {
        this.$swal.fire({
          title: "Algo sucedio!",
          text: "contacta con el administrador",
          icon: "error"
        });
      }
    },
    async editar(id) {
      try {
          let data = await this.$apiService.index(this.url + "view/" + id).then(data => data);
          if (data) {
            this.form = data;
            if(this.form.coviRoles){
                this.form.coviRoles = this.form.coviRoles.split(',');
            }
            this.categorias.search = this.form.coviCategoria;
            await this.filtrarCategorias();
          this.isNew = false;
        }
      } catch (error) {
        this.$swal.fire({
          title: "Algo sucedio!",
          text: "contacta con el administrador",
          icon: "error"
        });
      }
    },
    limpiar() {
      this.form = {
        coviId: null,
        coviTitulo: null,
        coviDescripcion: '',
        coviVisible:1,
        coviRoles:"",
        coviCategoria:"",
        coviLink:"",
        coviTipo:this.$route.name == 'Tutoriales'?'VIDEO':'DOCUMENTO'
      };
    },
    close() {
      this.isNew = true;
      this.dialog = false;
      this.dialog2 = false;
      this.limpiar();
    }
  }
};

</script>
